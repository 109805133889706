<template>
  <section id="content">
    <div class="content-wrap py-0">
      <div class="section nobg nomargin">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="heading-block center mb-4">
                <h2>Activate Your Fundraiser!</h2>
              </div>
              <p class="center">
                To verify your identity and start fundraising, M-Changa requires
                you to make the first mobile money donation of at least to
                Account 48741 using the number you registered with:
                <strong>083434535</strong>
              </p>
              <p class="center">
                After you make the payment, you will get an SMS and an Email
                notification on how you can access your account. <br />Thank you
                for choosing M-Changa.
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6">
              <form class="form-register-wrapper question-3 center">
                <img
                  class="mx-auto"
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/logo-flutterwave.png"
                />
                <p>
                  To Activate via Flutterwave: <br />Please click on this button
                </p>
                <div class="form-submit center flex justify-center">
                  <router-link to="/" class="button">Click Here</router-link>
                </div>
                <p class="text-xs md:text-base truncate">
                  OR copy below to your browser
                  <br />https://mchanga.africa/fundraiser/48741
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
